// @flow

import React from 'react';

/**
 * FollowContext
 *
 * creates the context for follow
 */
export const FollowContext = React.createContext({
  loadingFollowList: false,
  error: null,
  loaded: false,
  items: [],
  followerId: null,
  isAllowed: false,
});
