// @flow

import React from 'react';
import type { Node } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useAclAccess } from '@riseart/fe-utils';
import { application as CONFIG_APP } from 'Config';
import { useQuery } from 'shared_services/apollo/useQuery';
import { FollowContext } from 'shared_data/providers/follow/Context';
import { createFollow, deleteFollow } from 'shared_services/redux/actions/follow/follow';
import { selectAclRole } from 'shared_services/redux/selectors/auth';
import { selectUserId } from 'shared_services/redux/selectors/user';
import FILTER_FOLLOWS_QUERY from 'shared_data/queries/follow/filter.graphql';

type Props = {
  children: Node,
};

/**
 * FilterFollowsProvider
 */
export function FilterFollowsProvider({ children }: Props): Node {
  const dispatch = useDispatch();
  const { actionCreateFollow, actionDeleteFollow } = bindActionCreators(
    { actionCreateFollow: createFollow, actionDeleteFollow: deleteFollow },
    dispatch,
  );
  const userId = useSelector(selectUserId);
  const aclRole = useSelector(selectAclRole);
  const { isAllowed } = useAclAccess({
    role: aclRole,
    resourcePermissions: CONFIG_APP.acl.resourcePermissions.follow,
    rolesHierarchy: CONFIG_APP.acl.rolesHierarchy,
  });
  const {
    loading,
    error,
    data = {},
  } = useQuery(FILTER_FOLLOWS_QUERY, {
    variables: { followerId: userId },
    ssr: false,
    skip: !isAllowed || !userId,
  });
  const { items = [] } = (data && data.filterFollows) || {};

  return (
    <FollowContext.Provider
      value={{
        actionCreateFollow,
        actionDeleteFollow,
        loadingFollowList: loading,
        error,
        loaded: !loading,
        items: items.reduce(
          (accumulator, { leaderId, ...rest }) => ({ ...accumulator, [leaderId]: rest }),
          {},
        ),
        followerId: userId,
        isAllowed,
      }}
    >
      {children}
    </FollowContext.Provider>
  );
}
